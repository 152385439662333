import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ClerkProvider} from "@clerk/clerk-react";
import {TooltipProvider} from "./components/ui/tooltip";
import App from "./App";

const PUBLISHABLE_KEY = process.env?.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;
const API_HOST = process.env?.REACT_APP_API_HOST;

if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key");
}

if (!API_HOST) {
    throw new Error("Missing api host");
}

const getThemePreference = () => {
    if (typeof localStorage !== 'undefined' && localStorage.getItem('theme')) {
        return localStorage.getItem('theme');
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

const isDark = getThemePreference() === 'dark';
document.documentElement.classList[isDark ? 'add' : 'remove']('dark');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App api_host={API_HOST}/>,
        // element: <Dashboard/>,
    },
]);

const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
            <QueryClientProvider client={queryClient}>
                <TooltipProvider>
                    <RouterProvider router={router}/>
                </TooltipProvider>
            </QueryClientProvider>
        </ClerkProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
