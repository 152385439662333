import {Moon, Sun} from "lucide-react"

import {Button} from "src/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"


export function ModeToggle() {

    const setThemeState = (state: string) => {
        switch (state) {
            case 'light':
                localStorage.setItem('theme', 'light');
                document.documentElement.classList["remove"]("dark")
                break;

            case 'dark':
                localStorage.setItem('theme', 'dark');
                document.documentElement.classList["add"]("dark")
                break;
            default:
                localStorage.removeItem('theme');
                const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches
                document.documentElement.classList[isDark ? "add" : "remove"]("dark")
                return;
        }
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon">
                    <Sun
                        className="h-4 w-4 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"/>
                    <Moon
                        className="absolute h-4 w-4 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"/>
                    <span className="sr-only">Toggle theme</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => setThemeState("light")}>
                    Light
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setThemeState("dark")}>
                    Dark
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setThemeState("system")}>
                    System
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
