import {CardDescription, CardTitle} from "./components/ui/card";
import {Label} from "./components/ui/label";
import {Input} from "./components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "./components/ui/select";
import {Button} from "./components/ui/button";
import {LanguagesIcon, Loader2} from "lucide-react";
import {FormEvent, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Skeleton} from "./components/ui/skeleton";
import {ModeToggle} from "./components/ModeTogggle";
import {SignedIn, SignedOut, SignInButton, useAuth, UserButton} from "@clerk/clerk-react";
import {Badge} from "./components/ui/badge";
import {Dialog, DialogContent} from "./components/ui/dialog";
import {createPortal} from "react-dom";

interface AppProps {
    api_host: string
}

// Define section types for each language
const sectionTypes: { [key: string]: string[] } = {
    "en": ["Noun", "Verb", "Adjective"],
    "el": ["Ουσιαστικό", "Επίθετο", "Ρήμα"],
    // Add more languages and their respective section types here
};

const App = ({api_host, ...props}: AppProps) => {
    const {getToken} = useAuth();
    const [word, setWord] = useState('');
    const [language, setLanguage] = useState('en');
    const [open, setOpen] = useState(false)

    const {data: languages} = useQuery({
        queryFn: async () => {
            // temporary
            // const result = await fetch(`https://en.wiktionary.org/w/api.php?action=query&origin=*&meta=siteinfo&siprop=languages&format=json`);
            // return result.json();

            return {
                query: {
                    languages: [
                        {
                            "*": "English",
                            code: 'en'
                        },
                        {
                            "*": "Greek",
                            code: 'el'
                        }
                    ]
                }
            }
        },
        queryKey: ['languages']
    });

    const parseWiktionaryResponse = (response: any, language: string) => {
        try {
            if(!response?.["parse"]){
                return {};
            }

            const { sections, parsetree } = response?.["parse"];
            const parser = new DOMParser().parseFromString(parsetree, "text/xml");

            // Get section types for the specified language
            const languageSectionTypes = sectionTypes[language.toLowerCase()];

            if (!languageSectionTypes) {
                console.warn(`Section types not defined for language: ${language}`);
                return {};
            }

            let definitions: { [key: string]: string[] } = {};

            for (let type of languageSectionTypes) {
                const section = sections.find((element: any) => element.linkAnchor === type);

                if (!section) {
                    console.warn(`${type} section not found`);
                    continue;
                }

                const { index, level } = section;
                const startElement = parser.querySelector(`h[level="${level}"][i="${index}"]`);

                if (!startElement) {
                    console.warn(`Start element for ${type} not found`);
                    continue;
                }

                let elementsAndChildNodes: any[] = [];
                let currentNode = startElement.nextSibling;

                while (currentNode && currentNode.nodeName.toLowerCase() !== 'h') {
                    if (['template', '#text'].includes(currentNode.nodeName.toLowerCase()) && currentNode.textContent) {
                        elementsAndChildNodes.push(currentNode);
                    }
                    currentNode = currentNode.nextSibling;
                }

                let definitionString = elementsAndChildNodes.map(value => {
                    if (value instanceof Element) {
                        let part = value.querySelector('part:last-child');
                        return part && part.textContent ? part.textContent : '';
                    }
                    return value.textContent.replace(/\n/g, '');
                }).join('');

                definitions[type] = definitionString.split('#').filter(value => value.trim() !== '' && !['*', ':'].includes(value[0]));
            }

            return definitions;
        } catch (error) {
            console.error(error);
            return {};
        }
    }

    const {data: cardData, mutate: mutateCardData, isPending: cardsArePending} = useMutation({
        mutationFn: async () => {
            const wiktionaryResult = await fetch(`https://${language}.wiktionary.org/w/api.php?action=parse&origin=*&page=${word}&prop=parsetree|sections&format=json&formatversion=2`);

            const definitionData = parseWiktionaryResponse(await wiktionaryResult.json(), language);

            try {
                await fetch(`${api_host}/cards`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${await getToken()}`,
                    },
                    body: JSON.stringify({
                        title: word,
                        category: 'default',
                        content: definitionData,
                    }),
                    credentials: 'include',
                });

                // if (!result.ok) {
                //     throw new Error('Network response error')
                // }
            } catch (e) {
                console.log(e);
                return {}

            }

            return definitionData;
        },
        mutationKey: [`${word}`],
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        mutateCardData();
        setOpen(true);
    }

    return (
        <div className="flex flex-col container lg:mt-5">
            {/*<CardHeader>*/}
            <div className="space-y-1.5">
                <CardTitle>Translate</CardTitle>
                <CardDescription>Enter a word or phrase to translate.</CardDescription>
            </div>
            {/*</CardHeader>*/}
            {/*<CardContent>*/}
            <form onSubmit={onSubmit} className="mt-4">
                <div className="grid w-full items-center gap-4">
                    <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="text">Word</Label>
                        <Input
                            onChange={(e) => {
                                setWord(e.currentTarget.value);
                            }}
                            id="text"
                            placeholder="Enter word"
                        />

                    </div>
                    <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="language">Language</Label>
                        <Select value={language} onValueChange={setLanguage}>
                            <SelectTrigger id="language">
                                <SelectValue placeholder="Select"/>
                            </SelectTrigger>
                            <SelectContent position="popper">
                                {languages?.['query']?.['languages'].map((language: any) => {
                                    return <SelectItem key={language.code}
                                                       value={language.code}>{language["*"]}</SelectItem>
                                })}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                {cardsArePending ?
                    <Button disabled className="mt-4" type="submit">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                        Translate
                    </Button>
                    :
                    <Button disabled={word === ''} className="mt-4" type="submit">
                        Translate
                    </Button>
                }
            </form>
            <Dialog open={open} onOpenChange={setOpen} modal={true}>
                <DialogContent>
                    <div className="mt-4 p-4 border border-dashed rounded-lg flex items-center space-x-8">
                        <div className="grid gap-1.5">
                            <div className="flex items-center space-x-2">
                                <LanguagesIcon className="w-5 h-5 rounded-lg"/>
                                <div className="font-bold">{word}</div>
                            </div>
                            {sectionTypes[language]?.map(key => {
                                if (cardData?.[key]) {
                                    return (
                                        <div key={key}>
                                            <Badge className="flex-1">{key}</Badge>
                                            <ol className="list-decimal list-inside text-gray-500">
                                                {cardData[key].map((card, index) => {
                                                    return <li key={index}>{card}</li>
                                                })}
                                            </ol>
                                        </div>
                                    )
                                }

                                return <></>
                            })}
                        </div>
                        {cardsArePending && <Skeleton className="mt-4 h-99vh rounded-xl"/>}
                    </div>
                </DialogContent>
            </Dialog>
            {/*</CardContent>*/}
            <p className="text-sm opacity-50 self-end mr-1.5 mb-1.5">Definitions powered by <a className="underline"
                                                                                               href={`https://www.wiktionary.org/wiki/${word}`}>Wiktionary</a>
            </p>
            {createPortal(
                <>
                    <header>
                        <SignedOut>
                            <SignInButton mode='modal'/>
                        </SignedOut>
                        <SignedIn>
                            <UserButton />
                        </SignedIn>
                    </header>
                    <ModeToggle/>
                </>,
                document.getElementById('nav-portal') as HTMLElement)}
        </div>
    );
};

export default App;
